/// Apply focus styles to an element
///
/// @author Joe Adcock <joe@d3r.com>

@mixin focus($borderRadius: 4px, $color: $c-black, $width: 1px, $inside: false) {
    border-radius: $borderRadius;
    outline: none;

    @if type-of($color) == color {
        box-shadow: 0 0 0 $width $color;
    }
    @else if length($color) == 1 {
        box-shadow: 0 0 0 $width nth($color, 1);
    }
    @else if length($color) == 2 {
        @if $inside {
            box-shadow: inset 0 0 0 $width nth($color, 1), inset 0 0 0 ($width * 2) nth($color, 2);
        } @else {
            box-shadow: inset 0 0 0 $width nth($color, 1), 0 0 0 $width nth($color, 2);
        }
    }
}
