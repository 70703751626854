/// Apply margin (in a given direction) to an element, unless it is the last child in its container
///
/// @param {String} $direction Margin direction
/// @param {String} $margin Margin bottom value
/// @param {Bool} $flushLast [true] Should we make the last-child flush
/// @author Joe Adcock <joe@d3r.com>
/// @author Sean Clark <sean.clark@d3r.com>

@mixin margin($direction, $margin, $flushLast: true) {
    margin-#{$direction}: $margin;

    @if $flushLast {
        &:last-child {
            margin-#{$direction}: 0;
        }
    }
}
