@import 'tools/mixins/_margin';

.accordion-container {}

    .accordion-container__section {
        @include margin(bottom, 60px);

        @include breakpoint(tablet) {
            @include margin(bottom, 30px)
        }
    }

        .accordion-container__title {
            @include h2;
        }
