///
// Prefix definitions
//
// c: Colour values only
// f: Font value, this is only for values directly relating to fonts.
//    Remember to think about if it belongs in the map.
//    You can include colours but they must reference a c- variable
///

@import 'colours.json';

// Base pixel value
$base-px:                        16px;

$band-normal: 48px;

$band-tight: 24px;
$band-tight-mobile: 48px;

$band-tall: 64px;
$band-tall-mobile: 48px;

$band-tallest: 100px;
$band-tallest-mobile: 100px;
$band-tallest-mobile-adapt: 60px;

// Grid styles:
$grid-gutter-h:                  25px;
$grid-gutter-v:                  25px;
$grid-gutter-h--semi-tight:      20px;
$grid-gutter-v--semi-tight:      20px;
$grid-gutter-h--tight:           10px;
$grid-gutter-v--tight:           10px;
$grid-gutter-h--loose:           60px;
$grid-gutter-v--loose:           60px;

// Site layout sizes:
$site-max-width:                 1680px;
$site-min-width:                 300px;
$site-gutter:                    24px;
$site-gutter--mobile:            16px;
$site-width:                     100%;
$max-content-width:              550px;

// Fonts
$f-primary-stack:                'HK Grotesk', Arial, Helvetica, sans-serif;
$f-primary:                      (font-family: $f-primary-stack, line-height: 1.7, font-weight: 400);
$f-primary-color:                $c-body;

$f-title-stack:                  Cardo, serif;
$f-title:                        (font-family: $f-title-stack, line-height: 110%, text-transform: none, font-weight: 400, letter-spacing: normal, -moz-osx-font-smoothing: grayscale, -webkit-font-smoothing: antialiased);
$f-title-color:                  $c-title;

$f-subtitle-stack:               $f-primary-stack;
$f-subtitle:                     (font-family: $f-subtitle-stack, line-height: 1.3, letter-spacing: normal, font-weight: normal);
$f-subtitle-color:               $c-title;

$f-smallcaps-stack:              $f-primary-stack;
$f-smallcaps:                    (font-family: $f-smallcaps-stack, font-weight: 600, text-transform: uppercase, line-height: 1.5, tracking: 100);
$f-smallcaps-color:              $c-label;

$f-smallprint-stack:             $f-primary-stack;
$f-smallprint:                   (font-family: $f-smallprint-stack, font-weight: 600, line-height: 1.5);
$f-smallprint-color:             $c-label;

$f-field-stack:                  $f-primary-stack;
$f-field:                        (font-family: $f-field-stack, line-height: 1.5);
$f-field-color:                  $c-black;


$f-label-stack:                  $f-primary-stack;
$f-label:                        (font-family: $f-label-stack, line-height: 1.5);
$f-label-color:                  $c-black;

$f-button:                       $f-smallprint;

// Forms
$field-outline:                  rgba(0, 0, 0, .1) 0 0 20px 0px !default;

$headerHeight: 56px;
$headerHeightMobile: 60px;
$backHeight: 60px;
$parentLinkHeight: 60px;
$noticeHeight: 40px;

$headerNavRows: 8;
$headerNavColumns: 2;

$search-max-height: 400px;

$quantity-control-width: 38px;
$quantity-control-height: 38px;

$productCarouselWidth: $site-gutter--mobile * 1.5;

$buttonBorderRadius: 25px;

:root {
    --c-border: #000000;
    --c-panel-background: #FFFFFF;
    --c-disabled: #EEEEEE;
    --c-focus: #000000;

    --c-field-disabled: var(--c-disabled);
    --c-field-focus: var(--c-focus);

    --c-input-background: var(--c-panel-background);
    --c-input-border: var(--c-border);
}
