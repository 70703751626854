@import 'd3r-accordion/resources/sass/accordion';
@import 'tools/mixins/_focus';

.accordion__head {
    @include h4;

    padding: 13px 0 12px;
}

    .accordion__button {
        width: 100%;
        margin: -8px;
        padding: 8px;
        text-align: left;
        background: transparent;

        &:focus:not(:hover) {
            @include focus;
        }
    }

        .accordion__toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: normal;

            svg {
                fill: $c-black;
                width: 15px;
                height: 10px;
                margin-left: 1em;

                .accordion--active & {
                    transform: rotate(45deg);
                }
            }
        }

        .accordion__toggle--large {
            @include h2;
            padding-top: 16px;
            padding-bottom: 16px;
        }


    .accordion__body {
        padding-bottom: 60px;

        @include breakpoint(tablet) {
            padding-bottom: 40px;
        }
    }

        .accordion__body--flush {
            padding-bottom: 0;
        }
